import React from "react";
import {_getOnValueParams} from "../../../../services/FireBaseClientService";
import ID from "./ID";
import $ from 'jquery';
import ClientService from "../../../../services/ClientService";


export default class ServicesDropDownList extends React.Component {


    constructor(props) {
        super(props)
        let currentValue = props.defaultValue;
        let valueJson = currentValue ? JSON.parse(currentValue) : {};
        this.state = {
            services: [],
            service: null,
            students: [],
            file: null,
            answer: valueJson
        }
        this.onChange = this.onChange.bind(this);
        this.onFileChange = this.onFileChange.bind(this);
        this.onChangeStudent = this.onChangeStudent.bind(this);
    }

    componentDidMount() {
        let currentValue = this.props.defaultValue;
        let answer = currentValue ? JSON.parse(currentValue) : {};
        if (answer && answer.services && answer.services.length > 0) {
            if (answer.file) {
                this.changeFile(answer.file)
            }
            this.setState({
                services: answer.services,
                service: answer.service,
            })
        } else {
            _getOnValueParams('/api/getData', {
                query: 'allServices',
                params: '\{\"type\": \"beneficiaries\"\}'
            }, (res) => {
                answer.services = res.data.data.services
                this.setState({
                    services: res.data.data.services,
                    answer: answer
                })
            })
        }
    }

    updateService(service) {
        let answer = this.state.answer;
        answer.service = service;
        this.setState({
            service: service,
            answer: answer
        })
    }

    onChange(e) {
        let option = $(e.target).find("option[value='" + e.target.value + "']");
        console.log(e.target.value)
        this.updateService(e.target.value);
    }

    onChangeStudent(e) {
        let value = e.target.value;
        let values = value.split("/");
        let stakeholder = values[0];
        let status = values[1];

        let answer = this.state.answer;
        if (answer.students) {
            let stake = answer.students.filter(s => s.id === stakeholder)[0];
            answer.students = answer.students.map(s => {
                if (s.id === stakeholder) {
                    stake.status = status
                }
                return s;
            });
            this.setState({
                answer: answer
            })

        }
    }

    onFileChange(e) {
        let name = e.target.value;
        this.changeFile(name)
    }

    changeFile(name) {
        let groupInput = $('#collapseOne0 > div > div > div.react-form-builder-form > form > div:nth-child(1) > div > input')[0];
        let group = "";
        if (groupInput) {
            group = groupInput.value;
        }

        let params = {};
        params.type = "beneficiaries";
        params.groupId = group;

        ClientService.get('/api/rest/stakeholder/students/file/' + name, {params: params})
            .then(res => {
                let answer = this.state.answer;
                answer.file = name;
                let students = res.data.students;
                if (students && students.length > 0) {
                    students = answer.students && answer.students.length > 0 ? res.data.students.map(s => {
                        let stud = answer.students.filter(std => std.id === s.id)[0]
                        if (stud) {
                            s.status = stud.status;
                        }
                        return s;
                    }) : students;
                    answer.students = students;
                }


                this.setState({
                    students: res.data.students,
                    file: name,
                    answer: answer
                })
            });

    }


    render() {
        let valueJson = this.state.answer;
        let files = [];
        let serviceObj = {};
        let file = {}
        if (this.state.services && this.state.services.length > 0 && valueJson && valueJson.service) {
            serviceObj = this.state.services.filter(s => s.uuid === valueJson.service)[0];
            if (serviceObj) {
                files = serviceObj.files;
                file = this.state.file ? serviceObj.files.filter(f => f.name === this.state.file)[0] : null;
            }
        }
        if (file) {
            valueJson.file = file.name;
        }
        let stingValue = valueJson ? JSON.stringify(valueJson) : "";

        return (
            <>

                <input {...this.props} id={"parsedValue"} value={stingValue} type={"hidden"}/>

                <div className={"form-group "}>
                    <label className="form-label">
                        <span>Service - الخدمة</span>
                    </label>
                    <select className={"form-control"}
                            name={"service"}
                            onChange={this.onChange}
                            disabled={this.props.disabled}
                            defaultValue={this.state.service ? this.state.service : ""}
                            id={"services_list"}>
                        <option value="" key={"zero"}></option>
                        {this.state.services && this.state.services.filter(s => s.files.length > 0).map((tService) => {
                            const this_key = `service_preview_${ID.uuid()}`;
                            return <option value={tService.uuid} key={this_key}
                                           selected={serviceObj && serviceObj.uuid === tService.uuid}>{tService.name}</option>;
                        })}
                    </select>
                </div>

                <br/>
                {this.state.service &&
                    <div className={"form-group"}>
                        <label className="form-label">
                            <span>Service File</span>
                        </label>
                        <select name={"file"}
                                className={"form-control"}
                                disabled={this.props.disabled}
                                onChange={this.onFileChange}
                                id={"service_files_list"}>
                            <option value="" key={0}></option>
                            {files && files.map((ffile) => {
                                const this_key = `file_preview_${ID.uuid()}`;
                                return <option value={ffile.name} key={this_key}
                                               selected={file && file.name === ffile.name}>{ffile.formName}</option>;
                            })}
                        </select>
                    </div>
                }

                {this.state.students.map((student) => {
                    let studentAnswer = valueJson.students.filter(s => s.id === student.id)[0]
                    console.log("studentAnswer", studentAnswer);
                    let key = ID.uuid();
                    const this_key = `student_preview_${key}`;
                    return (
                        <div onChange={this.onChangeStudent} key={this_key} className={"form-group rfb-item"}
                             id={"student" + student.id}>
                            <label className="form-label">
                                {student.name}
                            </label>
                            <div key={"attendance"} className={"custom-control custom-radio option-inline"}>
                                <input id={"fid_P_" + key} className="custom-control-input" type={'radio'}
                                       disabled={this.props.disabled}
                                       defaultChecked={studentAnswer && studentAnswer.status === "p"}
                                       value={student.id + "/p"} name={this_key + student.id}/>
                                <label className="custom-control-label" htmlFor={"fid_P_" + key}>
                                    حضور - Attendance
                                </label>
                            </div>

                            <div key={"absent"} className={"custom-control custom-radio option-inline"}>
                                <input id={"fid_H_" + key} className="custom-control-input" type={'radio'}
                                       defaultChecked={studentAnswer && studentAnswer.status === "h"}
                                       disabled={this.props.disabled}
                                       value={student.id + "/h"} name={this_key + student.id}/>
                                <label className="custom-control-label" htmlFor={"fid_H_" + key}>
                                    Absent - غياب
                                </label>
                            </div>
                        </div>
                    );
                })}
            </>
        );
    }
}