import React from "react";
import ServicesDropDownList from "../page/formBuilder/customComponents/ServicesDropDownList";
import TrainersUnits from "../page/formBuilder/customComponents/TrainersUnits";

const Attendance = React.forwardRef((props, ref) => {
    return <ServicesDropDownList {...props} ref={ref}/>
})

const Trainers = React.forwardRef((props, ref) => {
    return <TrainersUnits {...props} ref={ref}/>
})

const formBuilderItmes = [
    {
        key: 'Header',
        name: 'Header Text',
        icon: 'fa fa-header',
        "static": true,
        content: 'Placeholder Text...'
    }, {
        key: 'Label',
        name: 'Label',
        "static": true,
        icon: 'fa fa-font',
        content: 'Placeholder Text...'
    }, {
        key: 'Paragraph',
        name: 'Paragraph',
        "static": true,
        icon: 'fa fa-paragraph',
        content: 'Placeholder Text...'
    }, {
        key: 'LineBreak',
        name: 'Line Break',
        "static": true,
        icon: 'fa fa-arrows-h'
    }, {
        key: 'Dropdown',
        canHaveAnswer: true,
        name: 'Dropdown',
        icon: 'fa fa-caret-square-o-down',
        label: 'Placeholder Label',
        field_name: 'dropdown_',
        options: []
    }, {
        key: 'Tags',
        canHaveAnswer: true,
        name: 'Tags',
        icon: 'fa fa-tags',
        label: 'Placeholder Label',
        field_name: 'tags_',
        options: []
    }, {
        key: 'Checkboxes',
        canHaveAnswer: true,
        name: 'Checkboxes',
        icon: 'fa fa-check-square-o',
        label: 'Placeholder Label',
        field_name: 'checkboxes_',
        options: []
    }, {
        key: 'RadioButtons',
        canHaveAnswer: true,
        name: 'Multiple Choice',
        icon: 'fa fa-dot-circle-o',
        label: 'Placeholder Label',
        field_name: 'radiobuttons_',
        options: []
    }, {
        key: 'TextInput',
        canHaveAnswer: true,
        name: 'Text Input',
        label: 'Placeholder Label',
        icon: 'fa fa-font',
        field_name: 'text_input_'
    }, {
        key: 'NumberInput',
        canHaveAnswer: true,
        name: 'Number Input',
        label: 'Placeholder Label',
        icon: 'fa fa-plus',
        field_name: 'number_input_'
    }, {
        key: 'TextArea',
        canHaveAnswer: true,
        name: 'Multi-line Input',
        label: 'Placeholder Label',
        icon: 'fa fa-text-height',
        field_name: 'text_area_'
    }, {
        key: 'Image',
        name: 'Image',
        label: '',
        icon: 'fa fa-photo',
        field_name: 'image_',
        src: ''
    }, {
        key: 'Rating',
        canHaveAnswer: true,
        name: 'Rating',
        label: 'Placeholder Label',
        icon: 'fa fa-star',
        field_name: 'rating_'
    }, {
        key: 'DatePicker',
        canDefaultToday: true,
        canReadOnly: true,
        dateFormat: 'yyyy/MM/dd',
        timeFormat: 'hh:mm aa',
        showTimeSelect: false,
        showTimeSelectOnly: false,
        name: 'Date',
        icon: 'fa fa-calendar',
        label: 'Placeholder Label',
        field_name: 'date_picker_'
    },
    {
        key: 'Signature',
        canReadOnly: true,
        name: 'Signature',
        icon: 'fa fa-pencil-square-o',
        label: 'Signature',
        field_name: 'signature_'
    }, {
        key: 'HyperLink',
        name: 'Web site',
        icon: 'fa fa-link',
        "static": true,
        content: 'Placeholder Web site link ...',
        href: 'http://www.example.com'
    }, {
        key: 'Download',
        name: 'File Attachment',
        icon: 'fa fa-file',
        "static": true,
        content: 'Placeholder file name ...',
        field_name: 'download_',
        file_path: '',
        _href: ''
    }, {
        key: 'Range',
        name: 'Range',
        icon: 'fa fa-sliders',
        label: 'Placeholder Label',
        field_name: 'range_',
        step: 1,
        default_value: 3,
        min_value: 1,
        max_value: 5,
        min_label: 'Easy',
        max_label: 'Difficult'
    }, {
        key: 'Camera',
        name: 'Camera',
        icon: 'fa fa-camera',
        label: 'Placeholder Label',
        field_name: 'camera_'
    },
    {
        key: 'Attendance',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: Attendance,
        type: 'custom',
        forwardRef: true,
        name: 'Attendance',
        icon: 'fa fa-caret-square-o-down',
        label: 'Attendance - الحضور',
        props: {className: "form-control"},
        field_name: 'Attendance_',
        options: []
    },
    {
        key: 'Trainers',
        canHaveAnswer: true,
        element: 'CustomElement',
        component: Trainers,
        type: 'custom',
        forwardRef: true,
        name: 'Trainers',
        icon: 'fa fa-caret-square-o-down',
        label: 'Trainers Units',
        props: {className: "form-control"},
        field_name: 'trainers_'
    }
];

export {
    formBuilderItmes,
    Attendance,
    Trainers
};