import {Registry} from "react-form-builder2";
import * as Inputs from "../components/shared/FormBuilderItmes";


const registerCustomComponents = () => {
    Registry.register('Attendance', Inputs.Attendance);
    Registry.register('Trainers', Inputs.Trainers);
    //Registry.register('Areas', Inputs.Areas);
}

export {registerCustomComponents}