// @flow
import AuthenticationService from "../../services/AuthenticationService";

/**
 * Checks if user is authenticated
 */
const isUserAuthenticated = () => {
    return AuthenticationService.isUserLoggedIn();
};

const getUserRole = async () => {
    try {
        let userRole = window.localStorage.getItem("UserRole");
        if (!userRole) {
            const res = await AuthenticationService.updateAndGetUserRole();
            userRole = res.data;
            window.localStorage.setItem("UserRole", res.data);
        }
        return userRole ? userRole : 'ANONYMOUS';
    } catch (e) {
        console.log('Error', e);
    }
    return 'ANONYMOUS';
}

const getUserRoleFromLocal = () => {
    const userRole = window.localStorage.getItem("UserRole");
    return userRole ? userRole : 'ANONYMOUS';
}

const updateUserRole = async () => {
    return await AuthenticationService.updateAndGetUserRole();
}

/**
 * Returns the logged in user
 */
const getLoggedInUser = () => {
    return AuthenticationService.getLoggedInUserName();
};

export {isUserAuthenticated, getLoggedInUser, getUserRole, getUserRoleFromLocal, updateUserRole};
