// Themes
import './theme/assets/scss/Saas.scss';
import 'font-awesome/css/font-awesome.min.css';


import React from 'react';
import './App.css';
import './labels.css';
import Routes from "./theme/routes/Routes";
import {registerCustomComponents} from "./utils/FormBuilderUtils";

function App() {
    registerCustomComponents();
    return (
        <Routes></Routes>
    );
}

export default App;
