import React from "react";
import ServerError from "../../../theme/pages/error/ServerError";


export default class ErrorBoundary extends React.Component {

    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
    }

    render() {
        const { hasError, error, info } = this.state;
        const { children } = this.props;

        return hasError ? <ServerError error={error} errorInfo={info}/> : children;
    }
}