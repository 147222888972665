import axios from 'axios';
import AuthenticationService, {TOKEN_SESSION_ATTRIBUTE_NAME,USER_NAME_SESSION_ATTRIBUTE_NAME} from "./AuthenticationService";
import { Redirect } from 'react-router-dom';
import configData from "../config";
import React from "react";

const instance = axios.create({
    baseURL: configData.SERVER_URL,
    withCredentials: true
});

instance.interceptors.response.use(response => {
    console.debug("Response from server 1:", response);
    return response;
}, error => {
    console.error("Response from server 2:", error.response || error.message);
    console.error("error.status", error.response.status);
    if(error.response.status === 403 ) {
        window.localStorage.removeItem(USER_NAME_SESSION_ATTRIBUTE_NAME);
        window.localStorage.removeItem(TOKEN_SESSION_ATTRIBUTE_NAME);
        window.location.href = "/";
    }
    throw error;
});

instance.interceptors.request.use(
    (config) => {
        if (AuthenticationService.isUserLoggedIn()) {
            let token = window.localStorage.getItem(TOKEN_SESSION_ATTRIBUTE_NAME)
            if (token === null) return config;
            config.headers.authorization = `Bearer ${token}`
        }
        return config
    }
)

export default instance;