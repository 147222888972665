import {getUserRoleFromLocal} from "../theme/helpers/authUtils";
import {USER_ROLE} from "../services/AuthenticationService";
import {decode as base64_decode} from 'base-64';

class RoleUtil {

    static getStakeHolderType = () => {
        const userRole = getUserRoleFromLocal();
        let role = userRole.split(/(?:-dataEntry|-admin)+/)[0];
        const stakholderType = role.replace(/-+/g, '_').toLowerCase();
        return stakholderType;
    }

    static isAdminRole = () => {
        const userRole = getUserRoleFromLocal();
        return userRole.endsWith("-admin");
    }

    static isDataEntry = () => {
        const userRole = getUserRoleFromLocal();
        return userRole.endsWith("-dataEntry");
    }

    static isBeneficiariesAdmin = () => {
        const userRole = getUserRoleFromLocal();
        return userRole === 'beneficiaries-admin';
    }

    static isBoothsAdmin = () => {
        const userRole = getUserRoleFromLocal();
        return userRole === 'booths-admin';
    }

    static isBeneficiariesDataEntry = () => {
        const userRole = getUserRoleFromLocal();
        return userRole === 'beneficiaries-dataEntry';
    }

    static isBeneficiariesAdminORDataEntry = () => {
        return this.isBeneficiariesAdmin() || this.isBeneficiariesDataEntry();
    }

    static isRoot = () => {
        const userRole = getUserRoleFromLocal();
        return userRole === ("root");
    }

    static isSuperAdmin = () => {
        const userRole = getUserRoleFromLocal();
        return userRole === ("super-admin");
    }

    static getUserRoles = () => {
        let decoded = base64_decode(window.localStorage.getItem(USER_ROLE));
        return JSON.parse(decoded);
    }


    static userHasManagedOnSection = (section) => {
        const {roles} = this.getUserRoles();
        const SHRole = roles[section];
        if (!SHRole) return true;
        return SHRole.hasManage;
    }
    static userHasWriteOnSection = (section) => {
        const {roles} = this.getUserRoles();
        const SHRole = roles[section];
        console.log('SHRole ' + section, SHRole)
        if (!SHRole) return true;
        return SHRole.hasWrite;
    }
    static userHasReadOnSection = (section) => {
        const {roles} = this.getUserRoles();
        const SHRole = roles[section];
        if (!SHRole) return true;
        return SHRole.hasRead;
    }
    static userHasDeleteOnSection = (section) => {
        const {roles} = this.getUserRoles();
        const SHRole = roles[section];
        if (!SHRole) return true;
        return SHRole.hasDelete;
    }

}

export default RoleUtil;
