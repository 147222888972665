import firebase from "../data/Firebase";
import ClientService from "./ClientService";


const _get = (path, callback) => {
    firebase
        .database()
        .ref(path)
        .on('value', callback);
};

const _getOnce = (path, callback) => {
    ClientService.get(path)
        .then(callback)
};

const _getOnValue = (path, attribute, value, callback) => {
    ClientService.get(path + "?" + attribute + "=" + value)
        .then(callback)
}


const _getOnValueParams = (path, params, callback) => {
    ClientService.get(path, {params:params})
        .then(callback)
}

const _delete = (path, id, callback) => {
    ClientService.delete(path)
        .then(callback)
}

const _add = (path, data, callback) => {
    ClientService.post(path, data)
        .then(callback);
}

const _update = (path, id, updates, callback) => {
    ClientService.put(path, updates)
        .then(callback);
}

const _clone = (path, callback) => {
    ClientService.post(path)
        .then(callback);
}

const _change = (path, data, callback) => {
    firebase
        .database()
        .ref(path)
        .set(data)
        .then(callback);
}

const _set = (path, id, data, callback) => {
    let ref = firebase
        .database()
        .ref(path);
    let child = ref.child(id);
    child
        .set(data)
        .then(callback);
}

//auth

const _login = (email, password) => {
    return firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
}

const _register = (email, password) => {
    return firebase
        .auth()
        .createUserWithEmailAndPassword(email, password);
}

const _logout = (afterLogout) => {
    firebase
        .auth().signOut().then(afterLogout)
}


export {_get, _delete, _getOnce, _add, _update, _getOnValue, _getOnValueParams, _set, _login, _logout, _register, _change, _clone};