import firebase from 'firebase'


const firebaseConfig = {
    apiKey: "AIzaSyC3T9f8cMjXNseb-uQzy0yICwAIybgjJQ0",
    authDomain: "al-aman-fund.firebaseapp.com",
    databaseURL: "https://al-aman-fund.firebaseio.com",
    projectId: "al-aman-fund",
    storageBucket: "al-aman-fund.appspot.com",
    messagingSenderId: "1058469676409",
    appId: "1:1058469676409:web:eb40843ba9f649e7d65ba8",
    measurementId: "G-6X3EFX1F6K"
};

const firebaseConfig_stag = {
    apiKey: "AIzaSyDd4HWF9EQpGGgF2JmHx9VcdezM1R76R8k",
    authDomain: "al-aman-stag.firebaseapp.com",
    databaseURL: "https://al-aman-stag.firebaseio.com",
    projectId: "al-aman-stag",
    storageBucket: "al-aman-stag.appspot.com",
    messagingSenderId: "464232269360",
    appId: "1:464232269360:web:7731385ca7bf40ff760ff5"
}

firebase.initializeApp(process.env.NODE_ENV === 'production' ? firebaseConfig : firebaseConfig_stag);

export default firebase;