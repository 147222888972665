// @flow
import {Cookies} from 'react-cookie';
import {all, call, fork, put, takeEvery} from 'redux-saga/effects';

import {fetchJSON, fetchLoginJSON, fetchRegistrationJSON} from '../../helpers/api';

import {FORGET_PASSWORD, LOGIN_USER, LOGOUT_USER, REGISTER_USER} from './constants';

import {
    forgetPasswordFailed,
    forgetPasswordSuccess,
    loginUserFailed,
    loginUserSuccess,
    registerUserFailed,
    registerUserSuccess,
} from './actions';
import {_logout} from "../../../services/FireBaseClientService";
import AuthenticationService from "../../../services/AuthenticationService";

/**
 * Sets the session
 * @param {*} user
 */
const setSession = user => {
    let cookies = new Cookies();
    if (user) {
        let coUser = {};
        coUser.displayName = user.displayName;
        coUser.email = user.email;
        coUser.emailVerified = user.emailVerified;
        coUser.photoURL = user.photoURL;
        coUser.isAnonymous = user.isAnonymous;
        coUser.uid = user.uid;
        coUser.providerData = user.providerData;
        let now = new Date();
        let minutes = 60;
        let hours = 24;
        now.setTime(now.getTime() + (hours * minutes * 60 * 1000));
        cookies.set('user', coUser, {path: '/', expires: now});
    } else cookies.remove('user', {path: '/'});
};

/**
 * Login the user
 * @param {*} payload - username and password
 */
function* login({payload: {email, password}}) {
    const options = {
        data: JSON.stringify({email, password}),
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
    };

    try {
        const user = yield call(fetchLoginJSON, email, password);
        setSession(user);
        yield put(loginUserSuccess(user));
    } catch (error) {
        let message;
        switch (error.code) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error.message;
        }
        yield put(loginUserFailed(message));
        setSession(null);
    }
}

/**
 * Logout the user
 * @param {*} param0
 */
function* logout({payload: {history}}) {
    try {
        AuthenticationService.logout();
        history.push('/account/login');
    } catch (error) {
    }
}

/**
 * Register the user
 */
function* register({payload: {fullname, email, password}}) {
    const options = {
        body: JSON.stringify({fullname, email, password}),
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
    };

    try {
        const response = yield call(fetchRegistrationJSON, email, password);
        yield put(registerUserSuccess(response));
    } catch (error) {
        let message;
        switch (error.code) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error.message;
        }
        yield put(registerUserFailed(message));
    }
}

/**
 * forget password
 */
function* forgetPassword({payload: {username}}) {
    const options = {
        body: JSON.stringify({username}),
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
    };

    try {
        const response = yield call(fetchJSON, '/users/password-reset', options);
        yield put(forgetPasswordSuccess(response.message));
    } catch (error) {
        let message;
        switch (error.status) {
            case 500:
                message = 'Internal Server Error';
                break;
            case 401:
                message = 'Invalid credentials';
                break;
            default:
                message = error;
        }
        yield put(forgetPasswordFailed(message));
    }
}

export function* watchLoginUser(): any {
    yield takeEvery(LOGIN_USER, login);
}

export function* watchLogoutUser(): any {
    yield takeEvery(LOGOUT_USER, logout);
}

export function* watchRegisterUser(): any {
    yield takeEvery(REGISTER_USER, register);
}

export function* watchForgetPassword(): any {
    yield takeEvery(FORGET_PASSWORD, forgetPassword);
}

function* authSaga(): any {
    yield all([fork(watchLoginUser), fork(watchLogoutUser), fork(watchRegisterUser), fork(watchForgetPassword)]);
}

export default authSaga;
