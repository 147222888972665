import ClientService from "./ClientService";
import {encode as base64_encode, decode as base64_decode} from 'base-64';

export const USER_NAME_SESSION_ATTRIBUTE_NAME = 'authenticatedUser'
export const TOKEN_SESSION_ATTRIBUTE_NAME = 'jwt-token'
export const USER_ROLE = 'user_roles'

class AuthenticationService {

    /* executeBasicAuthenticationService(username, password) {
         return ClientService.get(`/basicauth`,
             {headers: {authorization: this.createBasicAuthToken(username, password)}})
     }*/

    static executeJwtAuthenticationService = async (email, password) => {
        console.log(email);
        return await ClientService.post(`/api/rest/authenticate`, {
            email,
            password
        })
    }

    static registerNewUser = async (username, password, email) => {
        return await ClientService.post(`/api/rest/register`, {
            username,
            password,
            email
        })
    }

    static createBasicAuthToken = (username, password) => {
        return 'Basic ' + window.btoa(username + ":" + password)
    }

    /*registerSuccessfulLogin(username, password) {
        //let basicAuthHeader = 'Basic ' +  window.btoa(username + ":" + password)
        //console.log('registerSuccessfulLogin')
        window.localStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, username)
        this.setupAxiosInterceptors(this.createBasicAuthToken(username, password))
    }*/

    static registerSuccessfulLoginForJwt = (email, token, userRoles) => {
        window.localStorage.setItem(USER_NAME_SESSION_ATTRIBUTE_NAME, email)
        window.localStorage.setItem(TOKEN_SESSION_ATTRIBUTE_NAME, token)

        let encoded = base64_encode(JSON.stringify(userRoles));
        window.localStorage.setItem(USER_ROLE, encoded)

        this.setupAxiosInterceptors(this.createJWTToken(token))
    }

    static createJWTToken = (token) => {
        return 'Bearer ' + token
    }


    static logout = () => {
        window.localStorage.clear()
    }

    static isUserLoggedIn = () => {
        let user = window.localStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        return user !== null;

    }

    static isUserAuth = async () => {

        let token = window.localStorage.getItem(TOKEN_SESSION_ATTRIBUTE_NAME)
        if (token === null) return false
        const res = await ClientService.get(`/api/rest/isAuthenticated`);
        if (res.ok)
            return true;

        return false;

    }

    static getLoggedInUserName = () => {
        let user = window.localStorage.getItem(USER_NAME_SESSION_ATTRIBUTE_NAME)
        if (user === null) return ''
        return user
    }

    static updateAndGetUserRole = async () => {
        return await ClientService.get("/api/rest/user/role");
    }

    static getUserRolePromise = async () => {
        return await ClientService.get("/api/rest/user/role");
    }

    static setupAxiosInterceptors = (token) => {
        if (!token) {
            token = window.localStorage.getItem(TOKEN_SESSION_ATTRIBUTE_NAME);
        }
        ClientService.interceptors.request.use(
            (config) => {
                if (this.isUserLoggedIn()) {
                    config.headers.authorization = token
                }
                return config
            }
        )
    }
}

export default AuthenticationService;