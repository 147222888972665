import React, {Suspense} from 'react';
import {Redirect, Route} from 'react-router-dom';

import {isUserAuthenticated, getUserRoleFromLocal} from '../helpers/authUtils';
import ErrorBoundary from "../../components/page/error/ErrorBoundary";
import RoleUtil from "../../utils/RoleUtil";

// lazy load all the views

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const Logout = React.lazy(() => import('../pages/auth/Logout'));
const ErrorPageNotFound = React.lazy(() => import('../pages/error/PageNotFound'));
const ComingSoonPage = React.lazy(() => import('../../components/page/error/ComingSoonPage'));
const DataAnalyticsPage = React.lazy(() => import('../../components/page/analytics/DataAnalyticsPage'));

// MandE component
const editForm = React.lazy(() => import('../../components/page/surveys/files/EditFile'));
const renderForm = React.lazy(() => import('../../components/page/surveys/files/FileRenderer'));
const showSurvey = React.lazy(() => import('../../components/page/surveys/ShowSurvey'));
const surveys = React.lazy(() => import('../../components/page/surveys/SurveysList'));
const createFile = React.lazy(() => import('../../components/page/surveys/files/CreateFile'));
const stakeHolders = React.lazy(() => import('../../components/page/stackholders/StakeHoldersTable'));
const stakeHolderProfile = React.lazy(() => import('../../components/page/stackholders/StackHolder'));
const stakeHolderHistory = React.lazy(() => import('../../components/page/stackholders/history/StakeholderChangeHistory'));
const creatStackHolder = React.lazy(() => import('../../components/page/stackholders/CreateStakeHolder'));
const stakeHoldersettings = React.lazy(() => import('../../components/page/stackholders/StakeHolderSettings'));
const registration = React.lazy(() => import('../../components/page/Registration'));
const services = React.lazy(() => import('../../components/page/services/ServicesList'));
const createService = React.lazy(() => import('../../components/page/services/CreateService'));
const createServiceFile = React.lazy(() => import('../../components/page/services/files/CreateServiceFile'));
const showService = React.lazy(() => import('../../components/page/services/ShowService'));
const editService = React.lazy(() => import('../../components/page/services/EditService'));
const serviceSetting = React.lazy(() => import('../../components/page/services/ServiceSettings'));
const dashboards = React.lazy(() => import('../../components/page/dashboard/Dashboard'));
const tasks = React.lazy(() => import('../../components/page/task/TaskPage'));
const userManagement = React.lazy(() => import('../../components/page/userManagement/UserManagementPage'));
const exports = React.lazy(() => import('../../components/page/exports/ExportsList'));
const taskProfile = React.lazy(() => import('../../components/page/task/TaskProfile'));
const activityLog = React.lazy(() => import('../../components/page/audit/UserActivityLog'));
const claimsCreateFile = React.lazy(() => import('../../components/page/expensesClaims/formBuilder/CreateClaimFile'));
const listClaimsForms = React.lazy(() => import('../../components/page/expensesClaims/formBuilder/ListClaimsForms'));
const expensesClaims = React.lazy(() => import('../../components/page/expensesClaims/ExpensesClaimsMainPage'));

// handle auth and authorization

const loading = () => <div className="text-center"></div>;


const PrivateRoute = ({component: Component, roles, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{pathname: '/account/login', state: {from: props.location}}}/>;

            }
            //const loggedInUser = getLoggedInUser();
            const userRole = getUserRoleFromLocal();
            // check if route is restricted by role

            console.log('props.match.path', props.match.path)

            const path = props.match.path.substring(1);


            if (!RoleUtil.userHasReadOnSection(path)) {
                return <Redirect to={{pathname: '/stakeHolders'}}/>;
            } else if (path === '/admin/users') {
                if (!RoleUtil.isRoot()) {
                    return <Redirect to={{pathname: '/stakeHolders'}}/>;
                }
            }
            console.log("Continue.............................")


            // authorised so return component
            return <ErrorBoundary>
                <Suspense fallback={loading()}>
                    <Component {...props} />
                </Suspense>
            </ErrorBoundary>;
        }}
    />
);

const AnonymousRoute = ({component: Component, roles, ...rest}) => (
    <Route
        {...rest}
        render={props => {
            // authorised so return component
            return <ErrorBoundary>
                <Suspense fallback={loading()}>
                    <Component {...props} />
                </Suspense>
            </ErrorBoundary>;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    roles: ['root', 'super-admin'],
    component: () => <Redirect to="/dashboard"/>,
    route: PrivateRoute,
};

// MandE

const editFormRoutes = {
    path: '/:type/editForm/:form',
    name: 'Render Form',
    component: editForm,
    route: PrivateRoute
}

const renderFormRoutes = {
    path: '/renderForm/:form',
    name: 'Render Form',
    component: renderForm,
    route: Route
}

const renderFormRecordServiceRoutes = {
    path: '/renderForm/:form/service/:service',
    name: 'Render Form',
    component: renderForm,
    route: Route
}

const showSurveyRoutes = {
    path: '/survey/:form',
    name: 'Show Survey',
    component: showSurvey,
    route: AnonymousRoute
}

const surveysRoute = {
    path: '/surveys',
    name: 'Surveys',
    roles: ['root', 'super-admin'],
    icon: 'uil-files-landscapes',
    component: surveys,
    route: PrivateRoute
}

const createFileRoute = {
    path: '/files/create/:type',
    name: 'Create File',
    component: createFile,
    route: PrivateRoute
}

const registrationRoute = {
    path: '/registration',
    name: 'registration',
    component: registration,
    roles: ['root'],
    route: PrivateRoute
}

const stakeHoldersRoutes = {
    path: '/stakeHolders',
    name: 'StakeHolders',
    icon: 'dripicons-user-group',
    component: stakeHolders,
    route: PrivateRoute
}

const taskRoutes = {
    path: '/tasks',
    name: 'Tasks',
    icon: 'uil-clipboard-alt',
    component: tasks,
    route: PrivateRoute
}

const taskProfileRoutes = {
    path: '/task/:id',
    name: 'Tasks',
    component: taskProfile,
    route: PrivateRoute
}

const userManagementRoutes = {
    path: '/admin/users',
    name: 'User Managements',
    icon: 'mdi mdi-account-switch',
    component: userManagement,
    roles: ['root'],
    route: PrivateRoute
}

const activityLogRoutes = {
    path: '/admin/activityLog',
    name: 'Activity Log',
    icon: 'mdi mdi-timetable',
    component: activityLog,
    roles: ['root'],
    route: PrivateRoute
}

const expensesClaimsRoutes = {
    path: '/claims/main',
    name: 'Expenses Claims',
    icon: 'mdi mdi-account-switch',
    component: expensesClaims,
    route: PrivateRoute
}


const listClaimsFormsRoutes = {
    path: '/file/claims/list',
    name: 'List Expenses Claims Forms',
    component: listClaimsForms,
    route: PrivateRoute
}

const stakeHolderProfileRoutes = {
    path: '/:type/profile/:id',
    name: 'StakeHolder Profile',
    component: stakeHolderProfile,
    route: PrivateRoute
}

const stakeHolderHistoryRoutes = {
    path: '/:type/history/:id',
    name: 'StakeHolder History',
    component: stakeHolderHistory,
    route: PrivateRoute
}

const createstakeHoldersRoutes = {
    path: '/stakeHolders/create/:type',
    name: 'Create StakeHolder',
    component: creatStackHolder,
    route: PrivateRoute
}


const stakeHoldersSettingsRoutes = {
    path: '/stakeHolders/:type/settings',
    name: 'StakeHolder Settings',
    component: stakeHoldersettings,
    route: PrivateRoute
}

const servicesRoutes = {
    path: '/services',
    name: 'Services',
    icon: 'uil-list-ul',
    component: services,
    route: PrivateRoute
}

const analyticsRoutes = {
    path: '/analytics',
    name: 'Data Analytics',
    icon: 'uil-analytics',
    roles: ['root', 'super-admin'],
    component: DataAnalyticsPage,
    route: PrivateRoute
}


const createServiceRoutes = {
    path: '/services/create/:type',
    name: 'Create Service',
    component: createService,
    route: PrivateRoute
}

const createServiceFileRoutes = {
    path: '/service/files/create/:uuid',
    name: 'Create Service File',
    component: createServiceFile,
    route: PrivateRoute
}

const showServiceRoutes = {
    path: '/service/:uuid',
    name: 'Show Service',
    component: showService,
    route: PrivateRoute
}

const editServiceRoutes = {
    path: '/service/edit/:uuid',
    name: 'Edit Service',
    component: editService,
    route: PrivateRoute
}

const showServiceSettingsRoutes = {
    path: '/service/:uuid/settings',
    name: 'Show Service',
    component: serviceSetting,
    route: PrivateRoute
}

const dashboardsRoute = {
    path: '/dashboard',
    name: 'Dashboard',
    icon: 'uil-home-alt',
    header: 'Navigation',
    roles: ['root', 'super-admin'],
    route: PrivateRoute,
    component: dashboards
}

// dashboards
const dashboardRoutes = [
    dashboardsRoute,
    stakeHoldersRoutes,
    surveysRoute,
    servicesRoutes,
    analyticsRoutes,
    taskRoutes,
    expensesClaimsRoutes
];

// dashboards
const administrationRoutes = [
    {
        path: '/exports',
        name: 'Exports',
        icon: 'uil-export',
        roles: ['root', 'super-admin'],
        header: 'Administration',
        route: PrivateRoute,
        component: exports
    },
    activityLogRoutes,
    userManagementRoutes
];

//Error Handler
const errorHandlerRoutes = {
    name: '404 not found',
    route: PrivateRoute,
    component: ErrorPageNotFound
};
// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        }
    ],
};

const rootNonROOTROLE = {
    path: '/',
    exact: true,
    name: '',
    roles: ['root', 'super-admin'],
    hidden: true,
    component: () => <Redirect to="/stakeHolders"/>,
    route: PrivateRoute,
};


// flatten the list of all nested routes
const flattenRoutes = routes => {
    let flatRoutes = [];

    routes = routes || [];
    routes.forEach(item => {
        flatRoutes.push(item);
        if (typeof item.children !== 'undefined') {
            flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
        }
    });
    return flatRoutes;
};
// All routes
const allRoutes = [rootRoute, rootNonROOTROLE, authRoutes, createstakeHoldersRoutes, registrationRoute, stakeHoldersSettingsRoutes, stakeHolderProfileRoutes, taskProfileRoutes, createServiceFileRoutes, createServiceRoutes, showServiceSettingsRoutes, editServiceRoutes, showServiceRoutes, ...dashboardRoutes, createFileRoute, editFormRoutes, stakeHolderHistoryRoutes, renderFormRecordServiceRoutes, renderFormRoutes, showSurveyRoutes, ...administrationRoutes, listClaimsFormsRoutes, errorHandlerRoutes];

const authProtectedRoutes = [...dashboardRoutes, ...administrationRoutes];
//let authorizeRoutes = [...allRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
/*
if (RoleUtil.isDonationBoxesField()) {
    console.log('userRoleLocal',getUserRoleFromLocal());
    const rootRouteDonationBoxesFiled = {
        path: '/',
        exact: true,
        name: '',
        hidden:true,
        component: () => <Redirect to="/stakeHolders"/>,
        route: PrivateRoute,
    };
    const donationBoxFiled = [rootRouteDonationBoxesFiled, stakeHoldersRoutes, servicesRoutes];
    authorizeRoutes = [...donationBoxFiled];
    authProtectedRoutes = [...donationBoxFiled];
}

if (RoleUtil.isAdminRole() || RoleUtil.isDataEntry()) {
    const rootDataEntry = {
        path: '/',
        exact: true,
        name: '',
        hidden:true,
        component: () => <Redirect to="/stakeHolders"/>,
        route: PrivateRoute,
    };
    let dataEntry = [rootDataEntry, stakeHoldersRoutes, servicesRoutes, analyticsRoutes];
    if(RoleUtil.isAdminRole()){
        dataEntry = [dashboardsRoute, ...dataEntry];
    }
    authorizeRoutes = [...dataEntry];
    authProtectedRoutes = [...dataEntry];
}
*/

export {allRoutes, authProtectedRoutes, allFlattenRoutes};
