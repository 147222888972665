import ClientService from "../../services/ClientService";
import AuthenticationService from "../../services/AuthenticationService";

/**
 * Fetch data from given url
 * @param {*} url
 * @param {*} options
 */
const fetchJSON = (url, options = {}) => {
    return ClientService
        .request(url, options)
        .then(response => {
            if (!response.status === 200) {
                throw response;
            }
            return response;
        })
        .then(json => {
            return json;
        })
        .catch(error => {
            throw error;
        });
};


/**
 * Fetch data from given url
 * @param email
 * @param password
 */
const fetchLoginJSON = async (email, password) => {
    return await AuthenticationService.executeJwtAuthenticationService(email, password).then((res) => {
        window.localStorage.setItem("UserRole", res.data.role);
        const roles = {
            roles:res.data.userData.accessRules
        }
        AuthenticationService.registerSuccessfulLoginForJwt(email, res.data.jwt , roles)
        return res;
    }).catch((error) => {
        throw error;
    });
};

/**
 * Fetch data from given url
 * @param email
 * @param password
 */
const fetchRegistrationJSON = (username, email, password) => {
    return AuthenticationService.registerNewUser(username, password, email).then((res) => {
        console.log(res)
        return res;
    }).catch((error) => {
        throw error;
    });
};

export {fetchJSON, fetchLoginJSON, fetchRegistrationJSON};
