import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './index.css'
import { Provider } from 'react-redux';
import { configureStore } from './theme/redux/store';

import en from './translations/en.json'
import ar from './translations/ar.json'
import {setDefaultLanguage, setDefaultTranslations} from "react-multi-lang";
import {Cookies} from "react-cookie";

let cookies = new Cookies();
let lang = cookies.get('lang');
setDefaultTranslations({ar, en})
setDefaultLanguage(lang  ? lang : 'en');

ReactDOM.render(
    <Provider store={configureStore()}>
        <App />
    </Provider>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
