import React from "react";
import ID from "./ID";
import {Label} from "reactstrap";
import {AvForm, AvInput} from "availity-reactstrap-validation";


export default class TrainersUnits extends React.Component {


    constructor(props) {
        super(props)
        let currentValue = props.defaultValue;
        let valueJson = currentValue ? JSON.parse(currentValue) : {};
        this.state = {
            count: valueJson ? valueJson.count : 0,
            answer: valueJson
        }
        this.onChange = this.onChange.bind(this);
        this.onValueChange = this.onValueChange.bind(this);
    }

    onValueChange(e) {
        const self = this;
        let value = e.target.value;
        let idx = e.target.name.split('_')[2];
        let name = e.target.name.split('_')[1];

        self.setValues(name, value, idx);
    }


    setValues(name, value, idx) {
        let answer = this.state.answer;
        let trainers = answer.trainers && answer.trainers.length > 0 ? answer.trainers : [];
        let trainer = trainers && trainers.length > 0 && trainers[Number(idx)] ? trainers[Number(idx)] : {}
        if (name === "supportingFiles") {
            trainer[name] = value != "true";
            console.log(value)
        } else {
            trainer[name] = value;
        }
        if (trainers.length > 0) {
            trainers[Number(idx)] = trainer;
        } else {
            trainers.push(trainer);
        }
        answer.trainers = trainers;

        if (trainer.unitCost && trainer.trainingUnits) {
            trainer.totalUnitsCost = trainer.unitCost * trainer.trainingUnits;
            trainer.tax = trainer.totalUnitsCost * 0.05;
            trainer.totalUnitsCostAfterTax = trainer.totalUnitsCost - trainer.tax;
        } else {
            trainer.totalUnitsCost = 0;
            trainer.tax = 0;
            trainer.totalUnitsCostAfterTax = 0;
        }

        if (trainer.transportationUnits && trainer.transportationUnitCost) {
            trainer.totalTransportationAmount = trainer.transportationUnitCost * trainer.transportationUnits;
        } else {
            trainer.totalTransportationAmount = 0;
        }
        this.setState({
            answer: answer
        })
    }


    onChange(e) {
        let answer = this.state.answer;
        answer.count = e.target.value;

        this.setState({
            answer: answer,
            count: e.target.value
        })
    }


    render() {
        let valueJson = this.state.answer;

        if (valueJson.trainers && valueJson.trainers.length > 0) {
            valueJson.totalAmount = valueJson.trainers.map(trainer => {
                return Number(trainer.totalUnitsCostAfterTax) + Number(trainer.otherExpensesAmount) + Number(trainer.totalTransportationAmount);
            }).reduce((a, b) => a + b);
        }

        console.log(valueJson.totalAmount)

        let total = {}
        if (valueJson.trainers && valueJson.trainers.length > 0) {
            total = (
                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Total amount</span>
                    </label>
                    <input id={"trains_total_amount"} type={"number"} step={"any"}
                           name={"trains_totalAmount_"}
                           disabled={true}
                           defaultValue={valueJson ? Math.round(Number(valueJson.totalAmount)) : 0}
                           className={"form-control"}/>
                </div>

            )
        } else {
            total = (
                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Total amount</span>
                    </label>
                    <input id={"trains_total_amount"} type={"number"} step={"any"}
                           name={"trains_totalAmount_"}
                           disabled={true}
                           defaultValue={0}
                           className={"form-control"}/>
                </div>

            );
        }

        console.log(total)

        let stingValue = valueJson ? JSON.stringify(valueJson) : "";

        let counts = [];
        for (let i = 0; i < 10; i++) {
            let this_key = ID.uuid();
            counts.push(<option value={i} key={this_key}
                                selected={this.state.count == i}>{i}</option>);
        }

        let content = [];
        for (let i = 0; i < valueJson.count; i++) {
            let trainer = valueJson && valueJson.trainers && valueJson.trainers[i] ? valueJson.trainers[i] : {}
            content.push(<div key={"trainers_" + i}>
                <div className={"form-group"}>
                    <h3>Trainer {i + 1}</h3>
                    {/*Untis*/}
                    <label className="form-label">
                        <span>Name</span>
                    </label>
                    <input id={"train_name_" + i} type={"text"} name={"train_name_" + i}
                           disabled={this.props.disabled}
                           defaultValue={trainer ? trainer.name : ""}
                           onChange={this.onValueChange}
                           className={"form-control"}/>
                </div>

                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Description</span>
                    </label>
                    <input id={"train_description_" + i} type={"text"} name={"train_description_" + i}
                           disabled={this.props.disabled}
                           defaultValue={trainer ? trainer.description : ""}
                           onChange={this.onValueChange}
                           className={"form-control"}/>
                </div>

                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Training unit budget line</span>
                    </label>
                    <input id={"train_unit_budget_line_" + i} type={"text"} name={"train_trainingUnitBudgetLine_" + i}
                           disabled={this.props.disabled}
                           defaultValue={trainer ? trainer.trainingUnitBudgetLine : ""}
                           onChange={this.onValueChange}
                           className={"form-control"}/>
                </div>

                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Training Units</span>
                    </label>
                    <input id={"train_units_" + i} type={"number"} step={"any"} name={"train_trainingUnits_" + i}
                           disabled={this.props.disabled}
                           defaultValue={trainer ? trainer.trainingUnits : 0}
                           onChange={this.onValueChange}
                           className={"form-control"}/>
                </div>

                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Unit Cost</span>
                    </label>
                    <input id={"train_unit_cost" + i} type={"number"} step={"any"} name={"train_unitCost_" + i}
                           disabled={this.props.disabled}
                           defaultValue={trainer ? trainer.unitCost : 0}
                           onChange={this.onValueChange}
                           className={"form-control"}/>
                </div>

                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Total Units Cost</span>
                    </label>
                    <input id={"train_total_units_cost" + i} type={"number"} step={"any"}
                           name={"train_totalUnitsCost_" + i}
                           disabled={true}
                           defaultValue={trainer ? trainer.totalUnitsCost : 0}
                           className={"form-control"}/>
                </div>

                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Tax</span>
                    </label>
                    <input id={"train_tax" + i} type={"number"} step={"any"} name={"train_tax_" + i}
                           disabled={true}
                           defaultValue={trainer ? trainer.tax : 0}
                           className={"form-control"}/>
                </div>


                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Total Units Cost after Tax</span>
                    </label>
                    <input id={"train_total_units_cost_after_tax" + i} type={"number"} step={"any"}
                           name={"train_totalUnitsCostAfterTax_" + i}
                           disabled={true}
                           defaultValue={trainer ? trainer.totalUnitsCostAfterTax : 0}
                           className={"form-control"}/>
                </div>

                {/*Transport*/}
                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Transportation budget line</span>
                    </label>
                    <input id={"train_transport_budget_line_" + i} type={"text"}
                           name={"train_transportationBudgetLine_" + i}
                           disabled={this.props.disabled}
                           defaultValue={trainer ? trainer.transportationBudgetLine : ""}
                           onChange={this.onValueChange}
                           className={"form-control"}/>
                </div>

                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Transportation Units</span>
                    </label>
                    <input id={"train_transportation_units_" + i} type={"number"} step={"any"}
                           name={"train_transportationUnits_" + i}
                           disabled={this.props.disabled}
                           defaultValue={trainer ? trainer.transportationUnits : 0}
                           onChange={this.onValueChange}
                           className={"form-control"}/>
                </div>

                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Transportation Unit Cost</span>
                    </label>
                    <input id={"train_transportation_unit_cost" + i} type={"number"} step={"any"}
                           name={"train_transportationUnitCost_" + i}
                           disabled={this.props.disabled}
                           defaultValue={trainer ? trainer.transportationUnitCost : 0}
                           onChange={this.onValueChange}
                           className={"form-control"}/>
                </div>

                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Total Transportation Amount</span>
                    </label>
                    <input id={"train_total_transportation_amount" + i} type={"number"} step={"any"}
                           name={"train_totalTransportationAmount_" + i}
                           disabled={true}
                           defaultValue={trainer ? trainer.totalTransportationAmount : 0}
                           onChange={this.onValueChange}
                           className={"form-control"}/>
                </div>


                {/*Other Expenses*/}

                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Other expenses budget line</span>
                    </label>
                    <input id={"train_other_budget_line_" + i} type={"text"} name={"train_otherExpensesBudgetLine_" + i}
                           disabled={this.props.disabled}
                           defaultValue={trainer ? trainer.otherExpensesBudgetLine : ""}
                           onChange={this.onValueChange}
                           className={"form-control"}/>
                </div>


                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Other expenses description</span>
                    </label>
                    <input id={"train_otherDescription_" + i} type={"text"} name={"train_otherExpensesDescription_" + i}
                           disabled={this.props.disabled}
                           defaultValue={trainer ? trainer.otherExpensesDescription : ""}
                           onChange={this.onValueChange}
                           className={"form-control"}/>
                </div>

                <div className={"form-group"}>
                    <label className="form-label">
                        <span>Other expenses Amount</span>
                    </label>
                    <input id={"train_other_amount" + i} type={"number"} step={"any"}
                           name={"train_otherExpensesAmount_" + i}
                           disabled={this.props.disabled}
                           defaultValue={trainer ? trainer.otherExpensesAmount : 0}
                           onChange={this.onValueChange}
                           className={"form-control"}/>
                </div>

                <div className="custom-control custom-switch">
                    <AvForm onValidSubmit={() => {
                    }}>
                        <AvInput type="checkbox" name={"train_supportingFiles_" + i} id={"train_supporting_files" + i}
                                 checked={trainer.supportingFiles}
                                 onChange={this.onValueChange}
                                 className={"custom-control-input"}/>
                        <Label check for={"train_supporting_files" + i}
                               className={'custom-control-label'}>Supporting Files</Label>
                    </AvForm>
                </div>

                {trainer.supportingFiles && <div>

                    <div className={"form-group"}>
                        <label className="form-label">
                            <span>Attendance sheet</span>
                        </label>
                        <input id={"train_attendance_sheet_" + i} type={"text"} name={"train_attendanceSheet_" + i}
                               disabled={this.props.disabled}
                               defaultValue={trainer ? trainer.attendanceSheet : ""}
                               onChange={this.onValueChange}
                               className={"form-control"}/>
                    </div>


                    <div className={"form-group"}>
                        <label className="form-label">
                            <span>Time sheet</span>
                        </label>
                        <input id={"train_time_sheet_" + i} type={"text"} name={"train_timeSheet_" + i}
                               disabled={this.props.disabled}
                               defaultValue={trainer ? trainer.timeSheet : ""}
                               onChange={this.onValueChange}
                               className={"form-control"}/>
                    </div>

                    <div className={"form-group"}>
                        <label className="form-label">
                            <span>Agreement</span>
                        </label>
                        <input id={"train_agreement" + i} type={"text"} name={"train_agreement_" + i}
                               disabled={this.props.disabled}
                               defaultValue={trainer ? trainer.agreement : ""}
                               onChange={this.onValueChange}
                               className={"form-control"}/>
                    </div>

                    <div className={"form-group"}>
                        <label className="form-label">
                            <span>PSEA</span>
                        </label>
                        <input id={"train_psea" + i} type={"text"} name={"train_psea_" + i}
                               disabled={this.props.disabled}
                               defaultValue={trainer ? trainer.psea : ""}
                               onChange={this.onValueChange}
                               className={"form-control"}/>
                    </div>

                    <div className={"form-group"}>
                        <label className="form-label">
                            <span>G2P template</span>
                        </label>
                        <input id={"train_g2p_temp" + i} type={"text"} name={"train_g2pTemplate_" + i}
                               disabled={this.props.disabled}
                               defaultValue={trainer ? trainer.g2pTemplate : ""}
                               onChange={this.onValueChange}
                               className={"form-control"}/>
                    </div>

                    <div className={"form-group"}>
                        <label className="form-label">
                            <span>ID</span>
                        </label>
                        <input id={"train_id" + i} type={"text"} name={"train_id_" + i}
                               disabled={this.props.disabled}
                               defaultValue={trainer ? trainer.id : ""}
                               onChange={this.onValueChange}
                               className={"form-control"}/>
                    </div>

                </div>}


            </div>)
        }

        return (
            <>

                <input {...this.props} id={"trainersUnitsParsedValue"} value={stingValue} type={"hidden"}/>

                <div className={"form-group "}>
                    <label className="form-label">
                        <span>Number of Trainers</span>
                    </label>
                    <select className={"form-control"}
                            name={"trainersCount"}
                            onChange={this.onChange}
                            disabled={this.props.disabled}
                            defaultValue={this.state.count ? this.state.count : 0}
                            id={"trainersCount"}>
                        <option value="" key={"zero"}></option>
                        {counts}
                    </select>
                </div>

                <br/>

                {content}

                {total}
            </>
        );
    }
}